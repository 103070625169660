import Vue from 'vue'
import axios from 'axios'

const MyAPI = new Vue({
    data() {
        return {}
    },
    methods: {
        requestPasswordForgotEmail: function (postData, fnSuccess, fnError) {
            let url = Vue.prototype.$config.host + '/register/v1/passwordforgot'
            var params = {
                time: new Date().getTime()
            }
            console.log('postData', postData)
            axios({
                method: 'post',
                url: url,
                params: params,
                data: postData,
                responseType: 'json'
            })
                .then(function (response) {
                    Vue.prototype.$api.hideIdle()
                    if (fnSuccess) {
                        fnSuccess(response.data)
                    }
                })
                .catch(error => {
                    console.log(error.response)
                    Vue.prototype.$api.hideIdle()
                    if (fnError) {
                        if (error.response.data) {
                            fnError(error.response.data)
                        } else {
                            fnError(error.response)
                        }

                    } else {
                        Vue.prototype.$eventHub.$emit('errorDlg', error.response)
                    }
                })
        },
        update: function (postData, fnSuccess, fnError) {
            let url = Vue.prototype.$config.host + '/register/v1/update'
            var params = {
                time: new Date().getTime()
            }
            console.log('postData', postData)
            axios({
                method: 'post',
                url: url,
                params: params,
                data: postData,
                responseType: 'json'
            })
                .then(function (response) {
                    Vue.prototype.$api.hideIdle()
                    if (fnSuccess) {
                        fnSuccess(response.data)
                    }
                })
                .catch(error => {
                    console.log(error.response)
                    Vue.prototype.$api.hideIdle()
                    if (fnError) {
                        if (error.response.data) {
                            fnError(error.response.data)
                        } else {
                            fnError(error.response)
                        }

                    } else {
                        Vue.prototype.$eventHub.$emit('errorDlg', error.response)
                    }
                })
        },
        orderViewAccount: function (postData, fnSuccess, fnError) {
            let url = Vue.prototype.$config.host + '/register/v1/viewAccount'
            var params = {
                time: new Date().getTime()
            }
            console.log('postData', postData)
            axios({
                method: 'post',
                url: url,
                params: params,
                data: postData,
                responseType: 'json'
            })
                .then(function (response) {
                    Vue.prototype.$api.hideIdle()
                    if (fnSuccess) {
                        fnSuccess(response.data)
                    }
                })
                .catch(error => {
                    console.log(error.response)
                    Vue.prototype.$api.hideIdle()
                    if (fnError) {
                        if (error.response.data) {
                            fnError(error.response.data)
                        } else {
                            fnError(error.response)
                        }

                    } else {
                        Vue.prototype.$eventHub.$emit('errorDlg', error.response)
                    }
                })
        },
        deleteAccount: function (postData, fnSuccess, fnError) {
            let url = Vue.prototype.$config.host + '/register/v1/deleteAccount'
            var params = {
                time: new Date().getTime()
            }
            console.log('postData', postData)
            axios({
                method: 'post',
                url: url,
                params: params,
                data: postData,
                responseType: 'json'
            })
                .then(function (response) {
                    Vue.prototype.$api.hideIdle()
                    if (fnSuccess) {
                        fnSuccess(response.data)
                    }
                })
                .catch(error => {
                    console.log(error.response)
                    Vue.prototype.$api.hideIdle()
                    if (fnError) {
                        if (error.response.data) {
                            fnError(error.response.data)
                        } else {
                            fnError(error.response)
                        }

                    } else {
                        Vue.prototype.$eventHub.$emit('errorDlg', error.response)
                    }
                })
        },
        register: function (postData, fnSuccess, fnError) {
            let url = Vue.prototype.$config.host + '/register/v1/add'
            var params = {
                time: new Date().getTime()
            }
            console.log('postData', postData)
            axios({
                method: 'post',
                url: url,
                params: params,
                data: postData,
                responseType: 'json'
            })
                .then(function (response) {
                    Vue.prototype.$api.hideIdle()
                    if (fnSuccess) {
                        fnSuccess(response.data)
                    }
                })
                .catch(error => {
                    console.log(error.response)
                    Vue.prototype.$api.hideIdle()
                    if (fnError) {
                        if (error.response.data) {
                            fnError(error.response.data)
                        } else {
                            fnError(error.response)
                        }

                    } else {
                        Vue.prototype.$eventHub.$emit('errorDlg', error.response)
                    }
                })
        },
        generateToken(fnSuccess, fnError) {
            Vue.prototype.$api.showIdle()
            let url = Vue.prototype.$config.host + '/register/v1/token'
            let params = {
                time: new Date().getTime()
            }

            axios({
                method: 'get',
                params: params,
                url: url,
                responseType: 'json'
            })
                .then(function (response) {
                    Vue.prototype.$api.hideIdle()
                    if (fnSuccess) {
                        fnSuccess(response.data)
                    }
                }).catch(error => {
                    console.log(error.response)
                    Vue.prototype.$api.hideIdle()
                    if (fnError) {
                        fnError(error.response)
                    } else {
                        Vue.prototype.$eventHub.$emit('errorDlg', error.response)
                    }
            })
        }
    }
})
export default MyAPI
