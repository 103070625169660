<template>
  <v-app>
    <v-app-bar app color="white" flat v-if="true">
      <v-img src="static/logo_woohoo.png" max-width="90px" @click="gotohome"/>

      <v-spacer></v-spacer>
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="hidden-md-and-up"
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon x-large>menu_open</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              v-for="(item, i) in items"
              :key="i"
              @click="onClickMobileLink(item)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn color="#6f6f6e" depressed small text @click="gotourl" class="hidden-sm-and-down">
        Anmelden
      </v-btn>
      <v-btn color="#6f6f6e" depressed small text @click="jumpTo('/registrieren')" class="hidden-sm-and-down">
        Registrieren
      </v-btn>
      <v-btn color="#6f6f6e" depressed small text @click="scrollTo('pricesRef')" class="hidden-sm-and-down">
        Preise
      </v-btn>

      <v-btn color="#6f6f6e" depressed small text @click="scrollTo('contactRef')" class="hidden-sm-and-down">
        Kontakt
      </v-btn>
    </v-app-bar>
    <v-main class="pa-0">
      <router-view id="top"></router-view>
      <div class="allover" v-if="idleIsActive">
        <div style="margin-top: 20%">
          <center>
            <SelfBuildingSquareSpinner></SelfBuildingSquareSpinner>
            <br><br>
            <span class="idleText" v-if="idleText && idleText.length > 0">{{idleText}}</span>
          </center>
        </div>
        <v-container justify-center fill-height>
          <v-layout row>
            <v-flex cols="12" md="6" v-if="progress != 100" class="pa-4" style="margin-top: 10%">
              <v-progress-linear
                  color="light-blue"
                  height="10"
                  :value="progress"
                  striped
              ></v-progress-linear>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
      <!--<MyFooter></MyFooter>-->
      <CookieConsent style="background-color: white" button-text="Okay">
        <div class="text-h6 bluetext">
          Cookie Einstellung
        </div><br>
        <div class="mb-6">
          Cookies sind kleine Textdateien, die von Webseiten verwendet werden, um die Benutzererfahrung effizienter zu gestalten, bestimmte Funktionen bereitzustellen oder um bestimmte Auswertungen zu ermöglichen. Laut Gesetz können wir Cookies auf Ihrem Gerät speichern, wenn diese für den Betrieb dieser Seite unbedingt notwendig sind.
          <!--
          Diese Webseite verwendet Cookies, um Ihnen den bestmöglichen Service zu gewährleisten. Wenn Sie auf der Seite weitersurfen stimmen Sie der Cookie-Nutzung zu. Weitere Informationen
          -->
        </div>
        <template v-slot:link>
          <div>
            <v-btn depressed small text @click="openDS" class="pa-2">Weitere Informationen</v-btn>

          </div>
        </template>
      </CookieConsent>
      <!--
      <v-btn
          v-scroll="onScroll"
          v-show="fab"
          fab
          dark
          fixed
          bottom
          right
          class="ma-4"
          color="#8ab3ba"
          @click="toTop"
      >
        <v-icon color="white">keyboard_arrow_up</v-icon>
      </v-btn>
      -->
    </v-main>
    <Datenschutz ref="dlgDatenschutz"></Datenschutz>
  </v-app>
</template>

<script>
//import MyFooter from "./components/MyFooter"
import CookieConsent from "v-cookie-consent"
import Datenschutz from "./components/Datenschutz"
import Vue from "vue"
import {SelfBuildingSquareSpinner} from 'epic-spinners'

export default {
  name: 'App',

  components: {CookieConsent,Datenschutz, SelfBuildingSquareSpinner},
  methods: {
    OnProgress (progress) {
      console.log('OnProgress', progress)
      this.progress = progress
      if (progress === 100) {
        this.showIdle = false
      } else {
        this.showIdle = true
      }
    },
    OnStartIdle (data) {
      if (typeof data === 'boolean') {
        this.idleText = ''
        this.idleIsActive = data
      } else if (Array.isArray(data)) {
        this.idleText = ''
        this.idleIsActive = data[0]
        this.idleText = data[1]
      }
    },
    gotourl: function () {
      window.open("https://portal.einfachwoohoo.de/#/login", '_blank');
    },
    gotohome: function () {
      this.$router.push('/Home').catch(()=>{});
    },
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 5
      this.fab = top > 100
    },
    toTop () {
      //this.$vuetify.goTo(0)
      window.location = '#top'
    },
    openDS () {
      this.$refs.dlgDatenschutz.opendialog()
    },
    jumpTo (s) {
      this.$router.push(s)
    },
    scrollTo (name) {
      //  console.log('this.$refs[name]', name, this.$refs, this.$vuetify)
      if (this.$refs[name]) {
        this.$vuetify.goTo(this.$refs[name], this.scrollOptions)
      } else {
        this.$vuetify.goTo(document.getElementById(name), this.scrollOptions)
      }

      /*
      if (name === 'pricesRef') {
          this.$router.push({
              name: 'Home',
              path: '/Home' + new Date().getTime(),
              hash: name,
              params: {

              }
          }).catch(()=>{});
          return
      }
      if (name === 'downloadRef') {
          this.$router.push({
              name: 'Home',
              path: '/Home' + new Date().getTime(),
              hash: name,
              params: {

              }
          }).catch(()=>{});
          return
      }
      if (name === 'contactRef') {
          this.$router.push({
              name: 'Home',
              path: '/Home' + new Date().getTime(),
              hash: name,
              params: {

              }
          }).catch(()=>{});
          return
      }
      // window.location = this.$router.history.current.path + '#' + name
       */
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    onClickMobileLink (item) {
      console.log('onClickMobileLink', item)
      if (item.isEvent) {
        Vue.prototype.$eventHub.$emit('dialog', item.target)
        return
      }
      if (item.url) {
        window.open(item.url, '_blank')
        return
      }
      if (item.isScroller) {
        this.scrollTo(item.target)
      } else {
        this.$router.push(item.target)
      }
    }
  },
  data: () => ({
    idleText: '',
    progress: 100,
    idleIsActive: false,
    fab: false,
    scrollOptions: {
      duration: 100,
      offset: 0,
      easing: 'linear'
    },
    items: [
      { title: 'woohoo Portal', url: 'https://portal.einfachwoohoo.de/#/login' },
      { title: 'Registrieren', isScroller: false, target: '/registrieren' },
      { title: 'Preise', isScroller: true, target: 'pricesRef' },
      { title: 'Kontakt', isScroller: true, target: 'contactRef' },
      { title: 'Datenschutz', isEvent: true, target: 'datenschutz' },
      { title: 'Impressum', isEvent: true, target: 'impressum' },
      { title: 'Nutzungsbedingungen', isEvent: true, target: 'nutzungsbedingungen' }
    ],
    window: {
      width: 0,
      height: 0
    }
  }),
  mounted () {
    //console.log(this.$refs)
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    Vue.prototype.$eventHub.$on('OnIdle', this.OnStartIdle)
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    Vue.prototype.$eventHub.$off('OnIdle', this.OnStartIdle)
  }
};
</script>
<style lang="scss">
</style>
