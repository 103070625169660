import Vue from 'vue'
import Home from '@/components/Home'
import price from '@/components/price'
import Datenschutz from '@/components/Datenschutz'
import Impressum from '@/components/Impressum'
import Router from 'vue-router'
import Register from '@/components/register/Register01'
import PasswordForget from '@/components/register/PasswordForget'
import PasswordUpdate from '@/components/register/PasswordUpdate'
import AccountDelete from '@/components/register/AccountDelete'
import AccountView from '@/components/register/AccountView'
Vue.use(Router)
export default new Router({
  // eslint-disable-next-line no-unused-vars
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      let e = document.getElementById('' + to.hash)
      if (e) {
        var rect = e.getBoundingClientRect();
        return { x: 0, y: rect.top }
      }
    }
    return { x: 0, y: 0 }
  },
  mode:'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/registrieren',
      name: 'Register',
      component: Register
    },
    {
      path: '/passwort-vergessen',
      name: 'PasswordForget',
      component: PasswordForget
    },
    {
      path: '/passwort-neu-vergeben',
      name: 'PasswordUpdate',
      component: PasswordUpdate
    },
    {
      path: '/accountDelete',
      name: 'AccountDelete',
      component: AccountDelete
    },
    {
      path: '/accountView',
      name: 'AccountView',
      component: AccountView
    },
    {
      path: '/Home',
      name: 'Home2',
      component: Home
    },
    {
      path: '/Datenschutz',
      name: 'Datenschutz',
      component: Datenschutz
    },
    {
      path: '/Impressum',
      name: 'Impressum',
      component: Impressum
    },
    {
      path: '/price',
      name: 'price',
      component: price
    }
  ]
})
