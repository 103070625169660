<script>
import { Doughnut, mixins } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'
const {reactiveProp} = mixins
export default {
  name: 'PieChart',
  extends: Doughnut,
  plugins: [ChartDataLabels],
  props: ['chartData', 'options'],
  mixins: [reactiveProp],
  methods: {
    updateRendering () {
      console.log('options', this.options)
      this.renderChart(this.chartData, this.options)
    }
  },
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options)
  }
}
</script>

<style scoped>

</style>
