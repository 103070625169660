<template>
  <v-content>
    <dialog-register-done ref="dlgRegister"></dialog-register-done>
    <v-container>
      <v-row>
        <v-col
            cols="12"
            md="4"
        >
          <span>Du hast das Passwort vergessen kein Problem.</span>
          <span>Sende uns deine E-Mail Adresse und wir schicken dir eine E-Mail mit weiteren Informationen.</span>
        </v-col>
      </v-row>
    </v-container>
    <v-form v-model="valid" ref="form">
      <v-container>
        <v-row>
          <v-col
              cols="12"
              md="4"
              v-if="false"
          >
            <v-text-field
                v-model="userData.username"
                :rules="nameRules"
                :counter="10"
                label="Benutzername"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="4"
          >
            <v-text-field
                v-model="userData.email"
                :rules="emailRules"
                label="E-mail"
                required
            ></v-text-field>
          </v-col>

        </v-row>
        <v-btn @click="passwordMissed">Passwort neu vergeben</v-btn>
      </v-container>
    </v-form>
  </v-content>
</template>

<script>
import DialogRegisterDone from "@/components/dialogs/DialogRegisterDone";
export default {
  name: 'PasswordForget',
  components: {DialogRegisterDone},
  data: () => ({
    valid: false,
    api: require('./registerApi').default,
    userData: {
      token: '',
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      password1: ''
    },
    nameRules: [
      v => !!v || 'Name ist erforderlich',
      v => v && v.length <= 10 || 'Name must be less than 10 characters',
    ],
    emailRules: [
      v => !!v || 'E-mail ist erforderlich',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    password: '',
    password1: '',
    passwordRules: [
      v => !!v || 'Passwort ist erforderlich',
      v => v.length >= 8 || 'Passwort mindestens 8 characters',
    ],
  }),
  methods: {
    onGetToken: function (res) {
      console.log('token', res)
      this.userData.token = res.token
    },
    onRegisterFailed: function (res) {
      console.log('onRegisterFailed', res)
      this.$refs.dlgRegister.open({
        title: 'Anmeldung fehlgeschlagen.',
        message: res.message,
        failed: true
      })
    },
    onRegister: function (res) {
      console.log('onRegister', res)
      this.$refs.dlgRegister.open({
        title: 'Anmeldung erfolgreich',
        message: 'Wir haben eine E-Mail an ' + this.userData.email + ' gesendet. Bitte bestätigen Sie diese E-Mail mit einem Klick auf den gelieferten Link.',
        success: true
      })
    },
    passwordMissed: function () {
      console.log('this.', this.userData)
      if (this.$refs.form.validate()) {
        this.api.requestPasswordForgotEmail(this.userData, this.onRegister, this.onRegisterFailed)
      }

    }
  },
  mounted () {
    this.api.generateToken(this.onGetToken)
  }
}
</script>

<style scoped>

</style>
