import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
Vue.use(Vuetify);

export default new Vuetify({
    customVariables: ['~/sass/variables.sass'],
    theme: {
        primary: '#0e71b8',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        surfandscan: '#0e71b8'
    },
    icons: {
        iconfont: 'mdi',
    },
});

