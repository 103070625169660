<template>
    <v-main>
        <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
          <v-card>
            <v-app-bar flat app color="white">
              <v-img src="static/logo_woohoo_schrift.png" max-width="100px" @click="dialog = false"/>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
              </v-toolbar-items>
            </v-app-bar>
                <v-main>
                <v-col cols="12">
                    <v-card-text class="display-3 font-weight-bold mt-5 hidden-xs-only">
                        Impressum
                        <span itemprop="name">edologic.</span>
                    </v-card-text>
                    <v-card-text class="text-h6 font-weight-bold mt-5 hidden-sm-and-up">
                        Impressum
                        <span itemprop="name">edologic.</span>
                    </v-card-text>
                    <v-card-text class="headline font-weight-light">
                        edologic GmbH <br>
                        In der Hardt 3<br>
                        42399 Wuppertal<br>
                        <br><br>
                        Verantwortlicher i.S.d. § 6 Abs. 2 MDSTV
                        <br><br>
                        Matthias Ebel<br>
                        In der Hardt 3<br>
                        42399 Wuppertal<br>
                        <br><br>
                        Anbieter i.S.d. TSG/MDSTV
                        <br><br>
                        edologic GmbH<br>
                        In der Hardt 3<br>
                        42399 Wuppertal<br>
                        <br><br>
                        <strong>Marken- Wettbewerbs- und Urheberrechtlicher Kontakt</strong>
                        <br>
                        Sollten Sie der Ansicht sein, dass wir gegen Markenrechte oder Wettbewerbsrecht verstoßen oder dass Inhalte unserer Website Urheberrechte oder sonstige Leistungsschutzrechte verletzen oder wenn Sie eine Frage rechtlicher Art zu mein Unternehmen und/oder Internetauftritt haben, nutzen Sie bitte meine Korrespondenzadresse:
                        <br><br>
                        Matthias Ebel<br>
                        In der Hardt 3<br>
                        42399 Wuppertal<br>
                        <br><br>

                        <strong>Haftungshinweis</strong>
                        <br>
                        Die Informationen, die auf diesen Seiten abgerufen werden können, erheben weder einen Anspruch auf Richtigkeit noch auf Vollständigkeit. Die Benutzung erfolgt auf eigene Gefahr. Wir weisen ausdrücklich darauf hin, dass wir für die Angebote Dritter im World Wide Web (sogenannte „deep links“) nicht verantwortlich sind. Soweit Links auf die Angebote Dritter verweisen, enthalten diese nach unserer Kenntnis keine strafbaren oder anderweitig verbotenen Inhalte. Für diese Inhalte wird keinerlei Haftung übernommen. Diese Erklärung gilt für alle auf matthiasebel.de aufgebrachten Links oder Kommentare. matthiasebel.de distanziert sich von allen Aussagen, die hier durch Gastkommentare getätigt werden. Beiträge mit rechts- oder sittenwidrigen Inhalten werden so schnell wie möglich entfernt.
                        <br>
                        <br>
                        <strong>Urheberrechtlicher Hinweis</strong>
                        <br>
                        Inhalt und Struktur der Internet-Seiten von matthiasebel.de sind urheberrechtlich geschützt. Jegliche Vervielfältigung von Informationen oder Daten, insbesondere die Verwendung von Texten, Textteilen, Bildmaterial oder sonstigen Inhalten bedarf der vorherigen Zustimmung durch den Anbieter bzw. Rechteinhaber. Alle Grafiken, Bilder, Texte und sonstigen Daten im Umfang dieses Internet-Angebotes sind urheberrechtlich geschützt und sind, soweit nicht anders angegeben oder erkennbar, Eigentum des Herausgebers. Die Vervielfältigung jeglicher Daten von diesem Internet-Angebot ohne vorherige Zustimmung des Herausgebers ist untersagt, dies gilt insbesondere für die Vervielfältigung sowie die Speicherung und Bearbeitung in elektronischen Medien.
                        <br><br>

                        <strong>Rechtliche Hinweise</strong>
                        <br>
                        Der Herausgeber dieser Seiten kann keinerlei Gewähr für die Richtigkeit aller Daten und Angaben übernehmen. Ein Rechts- oder Ersatzanspruch aufgrund falscher Darstellungen (soweit sie nicht Ehren- oder Sittenrechte bzw. sonstige Gesetze verletzen) wird hiermit zurückgewiesen. Der Herausgeber weist ausdrücklich darauf hin, dass für den Inhalt von verlinkten Seiten der jeweilige Betreiber die Verantwortung trägt.
                    </v-card-text>
                    <v-card-text class="display-3 font-weight-bold hidden-xs-only">
                        DISCLAIMER – RECHTLICHE HINWEISE
                    </v-card-text>
                    <v-card-text class="text-h6 font-weight-bold hidden-sm-and-up">
                        DISCLAIMER – RECHTLICHE HINWEISE
                    </v-card-text>
                    <v-card-text class="headline font-weight-light mb-5">
                        <strong>§ 1 Haftungsbeschränkung</strong>
                        <br>
                        Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt erstellt.
                        Der Anbieter übernimmt jedoch keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Inhalte.
                        Die Nutzung der Inhalte der Website erfolgt auf eigene Gefahr des Nutzers. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder.
                        Mit der reinen Nutzung der Website des Anbieters kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande.
                        <br><br>
                        <strong>§ 2 Externe Links</strong>
                        <br>
                        Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links").
                        Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die fremden Inhalte daraufhin überprüft,
                        ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich.
                        Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten.
                        Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht.
                        Eine ständige Kontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar.
                        Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht.
                        <br><br>
                        <strong>§ 3 Urheber- und Leistungsschutzrechte</strong>
                        <br>
                        Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht.
                        Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers.
                        Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen.
                        Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar.
                        Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.
                        <br><br>
                        Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.
                        <br><br>
                        <strong>§ 4 Besondere Nutzungsbedingungen</strong>
                        <br>
                        Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Paragraphen abweichen, wird an entsprechender Stelle ausdrücklich darauf hingewiesen.
                        In diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen.
                        Quelle: Impressum-Vorlage von <a href="http://www.juraforum.de/">www.juraforum.de</a>
                    </v-card-text>
                </v-col>
                </v-main>
            </v-card>
        </v-dialog>
        <!--<v-container fluid class="overflow-hidden">
            <v-row>
                <v-col cols="12">
                    <v-card-text class="display-3 font-weight-bold mt-5 hidden-xs-only">
                        Impressum
                        <span itemprop="name">edologic.</span>
                    </v-card-text>
                    <v-card-text class="text-h6 font-weight-bold mt-5 hidden-sm-and-up">
                        Impressum
                        <span itemprop="name">edologic.</span>
                    </v-card-text>
                    <v-card-text class="headline font-weight-light">
                        edologic GmbH <br>
                        In der Hardt 3<br>
                        42399 Wuppertal<br>
                        <br><br>
                        Verantwortlicher i.S.d. § 6 Abs. 2 MDSTV
                        <br><br>
                        Matthias Ebel<br>
                        In der Hardt 3<br>
                        42399 Wuppertal<br>
                        <br><br>
                        Anbieter i.S.d. TSG/MDSTV
                        <br><br>
                        edologic GmbH<br>
                        In der Hardt 3<br>
                        42399 Wuppertal<br>
                        <br><br>
                        <strong>Marken- Wettbewerbs- und Urheberrechtlicher Kontakt</strong>
                        <br>
                        Sollten Sie der Ansicht sein, dass wir gegen Markenrechte oder Wettbewerbsrecht verstoßen oder dass Inhalte unserer Website Urheberrechte oder sonstige Leistungsschutzrechte verletzen oder wenn Sie eine Frage rechtlicher Art zu mein Unternehmen und/oder Internetauftritt haben, nutzen Sie bitte meine Korrespondenzadresse:
                        <br><br>
                        Matthias Ebel<br>
                        In der Hardt 3<br>
                        42399 Wuppertal<br>
                        <br><br>

                        <strong>Haftungshinweis</strong>
                        <br>
                        Die Informationen, die auf diesen Seiten abgerufen werden können, erheben weder einen Anspruch auf Richtigkeit noch auf Vollständigkeit. Die Benutzung erfolgt auf eigene Gefahr. Wir weisen ausdrücklich darauf hin, dass wir für die Angebote Dritter im World Wide Web (sogenannte „deep links“) nicht verantwortlich sind. Soweit Links auf die Angebote Dritter verweisen, enthalten diese nach unserer Kenntnis keine strafbaren oder anderweitig verbotenen Inhalte. Für diese Inhalte wird keinerlei Haftung übernommen. Diese Erklärung gilt für alle auf matthiasebel.de aufgebrachten Links oder Kommentare. matthiasebel.de distanziert sich von allen Aussagen, die hier durch Gastkommentare getätigt werden. Beiträge mit rechts- oder sittenwidrigen Inhalten werden so schnell wie möglich entfernt.
                        <br>
                        <br>
                        <strong>Urheberrechtlicher Hinweis</strong>
                        <br>
                        Inhalt und Struktur der Internet-Seiten von matthiasebel.de sind urheberrechtlich geschützt. Jegliche Vervielfältigung von Informationen oder Daten, insbesondere die Verwendung von Texten, Textteilen, Bildmaterial oder sonstigen Inhalten bedarf der vorherigen Zustimmung durch den Anbieter bzw. Rechteinhaber. Alle Grafiken, Bilder, Texte und sonstigen Daten im Umfang dieses Internet-Angebotes sind urheberrechtlich geschützt und sind, soweit nicht anders angegeben oder erkennbar, Eigentum des Herausgebers. Die Vervielfältigung jeglicher Daten von diesem Internet-Angebot ohne vorherige Zustimmung des Herausgebers ist untersagt, dies gilt insbesondere für die Vervielfältigung sowie die Speicherung und Bearbeitung in elektronischen Medien.
                        <br><br>

                        <strong>Rechtliche Hinweise</strong>
                        <br>
                        Der Herausgeber dieser Seiten kann keinerlei Gewähr für die Richtigkeit aller Daten und Angaben übernehmen. Ein Rechts- oder Ersatzanspruch aufgrund falscher Darstellungen (soweit sie nicht Ehren- oder Sittenrechte bzw. sonstige Gesetze verletzen) wird hiermit zurückgewiesen. Der Herausgeber weist ausdrücklich darauf hin, dass für den Inhalt von verlinkten Seiten der jeweilige Betreiber die Verantwortung trägt.
                    </v-card-text>
                    <v-card-text class="display-3 font-weight-bold hidden-xs-only">
                        DISCLAIMER – RECHTLICHE HINWEISE
                    </v-card-text>
                    <v-card-text class="text-h6 font-weight-bold hidden-sm-and-up">
                        DISCLAIMER – RECHTLICHE HINWEISE
                    </v-card-text>
                    <v-card-text class="headline font-weight-light mb-5">
                        <strong>§ 1 Haftungsbeschränkung</strong>
                        <br>
                        Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt erstellt.
                        Der Anbieter übernimmt jedoch keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Inhalte.
                        Die Nutzung der Inhalte der Website erfolgt auf eigene Gefahr des Nutzers. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder.
                        Mit der reinen Nutzung der Website des Anbieters kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande.
                        <br><br>
                        <strong>§ 2 Externe Links</strong>
                        <br>
                        Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links").
                        Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die fremden Inhalte daraufhin überprüft,
                        ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich.
                        Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten.
                        Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht.
                        Eine ständige Kontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar.
                        Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht.
                        <br><br>
                        <strong>§ 3 Urheber- und Leistungsschutzrechte</strong>
                        <br>
                        Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht.
                        Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers.
                        Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen.
                        Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar.
                        Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.
                        <br><br>
                        Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.
                        <br><br>
                        <strong>§ 4 Besondere Nutzungsbedingungen</strong>
                        <br>
                        Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Paragraphen abweichen, wird an entsprechender Stelle ausdrücklich darauf hingewiesen.
                        In diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen.
                        Quelle: Impressum-Vorlage von <a href="http://www.juraforum.de/">www.juraforum.de</a>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-container>-->
    </v-main>
</template>

<script>
//import Vue from 'vue'

export default {
  name: 'Impressum',
  components: {},
  data () {
    return {
        fab: false,
        dialog: false,
        scrollOptions: {
            duration: 2100,
            offset: 0,
            easing: 'easeInOutCubic'
        },
        window: {
            width: 0,
            height: 0
        }
    }
  },
  methods: {
      opendialog () {
          this.dialog = true
      },
      onScroll (e) {
          if (typeof window === 'undefined') return
          const top = window.pageYOffset ||   e.target.scrollTop || 5
          this.fab = top > 0
      },
      toTop () {
          //this.$vuetify.goTo(0)
          window.location = '#top'
      },
      scrollTo (name) {
          // this.$vuetify.goTo(this.$refs[name], this.scrollOptions)
          window.location = '#' + name
      },
  },
  mounted () {
    //Vue.prototype.$api.checkCredentials(this)
  }
}
</script>

<style scoped>

</style>
