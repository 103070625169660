import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import myConfig from './config.js'
import API from './api.js'
import './main.scss'
Vue.config.productionTip = false
Vue.prototype.$config = myConfig
Vue.prototype.$api = API
Vue.prototype.$eventHub = new Vue() // Global event bus
new Vue({
  vuetify,
  router: router,
  render: h => h(App)
}).$mount('#app')
Vue.config.devtools = true;
