<template>
  <v-content>
    <dialog-register-done ref="dlgRegister" @onClose="onCloseDialog"></dialog-register-done>
    <v-container>
      <v-row>
        <v-col
            cols="12"
            md="4"
        >
          <span>Passwort neu vergeben</span>
        </v-col>
      </v-row>
    </v-container>
    <v-form v-model="valid" ref="form">
      <v-container>
        <v-row>
          <v-col
              cols="12"
              md="4"
          >
            <v-text-field
                v-model="userData.token"
                label="Token"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="4"
          >
            <v-text-field
                v-model="userData.password"
                :rules="passwordRules"
                label="Passwort"
                type="password"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="4"
          >
            <v-text-field
                v-model="userData.password1"
                :rules="passwordRules"
                type="password"
                label="Passwort Bestätigung"
                required
            ></v-text-field>
          </v-col>

        </v-row>
        <v-btn @click="register">Passwort neu setzen</v-btn>
      </v-container>
    </v-form>
  </v-content>
</template>

<script>
import DialogRegisterDone from "@/components/dialogs/DialogRegisterDone";
import Vue from 'vue'
export default {
  name: 'PasswordUpdate',
  components: {DialogRegisterDone},
  data: () => ({
    valid: false,
    api: require('./registerApi').default,
    dialogForwardLink: '',
    userData: {
      token: '',
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      password1: ''
    },
    nameRules: [
      v => !!v || 'Name ist erforderlich',
      v => v && v.length <= 10 || 'Name must be less than 10 characters',
    ],
    emailRules: [
      v => !!v || 'E-mail ist erforderlich',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    password: '',
    password1: '',
    passwordRules: [
      v => !!v || 'Passwort ist erforderlich',
      v => v.length >= 8 || 'Passwort mindestens 8 characters',
    ],
  }),
  methods: {
    onGetToken: function (res) {
      console.log('token', res)
      this.userData.token = res.token
    },
    onPWFailed: function (res) {
      console.log('onPWFailed', res)
      this.$refs.dlgRegister.open({
        title: 'Anmeldung fehlgeschlagen.',
        message: res.message,
        failed: true
      })
    },
    onCloseDialog: function (config) {
      if (config.dialogForwardLink) {
        window.location = config.dialogForwardLink
      }
    },
    onPWUpdate: function (res) {
      console.log('onPWUpdate', res)
      if (res.success) {
        this.$refs.dlgRegister.open({
          dialogForwardLink: Vue.prototype.$config.loginMask + '?username='+res.username,
          title: 'Erfolgreich gesetzt',
          message: 'Das Passwort wurde erfolgreich für den Nutzer "' + res.username + '" gesetzt.',
          success: true
        })
      }
    },
    register: function () {
      console.log('this.', this.userData)
      if (this.$refs.form.validate()) {
        this.api.update(this.userData, this.onPWUpdate, this.onPWFailed)
      }

    }
  },
  mounted () {
    console.log('', this.$route.query.token)
    this.userData.token = this.$route.query.token
  }
}
</script>

<style scoped>

</style>
