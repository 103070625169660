
import Vue from 'vue'
import axios from 'axios'
/**
 * Vue.prototype.$api.downloadSurveyStatsAsExcel
 * @type {{loadExamQuestions: BaseApi.loadExamQuestions, loadExams: BaseApi.loadExams, hideIdle: BaseApi.hideIdle, loadParticipants: BaseApi.loadParticipants, archiveStats: BaseApi.archiveStats, setCredentials: BaseApi.setCredentials, checkCredentials: BaseApi.checkCredentials, loadSurveys: BaseApi.loadSurveys, removeUser: BaseApi.removeUser, resetCredentials: BaseApi.resetCredentials, loadQuestions: BaseApi.loadQuestions, loadSystemGroupList: BaseApi.loadSystemGroupList, loadExam: BaseApi.loadExam, boolValueOfExam: BaseApi.boolValueOfExam, downloadExamRankList: BaseApi.downloadExamRankList, loadHistory: BaseApi.loadHistory, calculateExam: BaseApi.calculateExam, downloadExamPointList: BaseApi.downloadExamPointList, loadTests: BaseApi.loadTests, saveSystemGroupRights: BaseApi.saveSystemGroupRights, loadQuestion: BaseApi.loadQuestion, loadParticipantsWithAnswers: BaseApi.loadParticipantsWithAnswers, onGetError: BaseApi.onGetError, loadStats: BaseApi.loadStats, showIdle: BaseApi.showIdle, loadGroup: BaseApi.loadGroup, downloadExamGroupList: BaseApi.downloadExamGroupList, downloadExamNotesAsExcel: BaseApi.downloadExamNotesAsExcel, loadSystemGroupRights: BaseApi.loadSystemGroupRights}}
 */
const BaseApi = {
  subscriptionList: [],
  showIdle: function (cmp, cmd, txt) {
    Vue.prototype.$eventHub.$emit('OnIdle', [true, txt])
    if (cmp && cmd) {
      cmp.$nextTick(() => {
        cmd()
      })
    }
  },
  getSubscriptionWithName (name) {
    for (let i in this.subscriptionList) {
      let item = this.subscriptionList[i]
      if (item.name === name)
        return item
    }
    return {
      ident: name.toLowerCase(),
      name: name
    }
  },
  convertToDecimal (format, value) {
    let oldValue = value
    try {
      // value = ('' + value).replace(/\./g, '')
      // value = ('' + value).replace(/,/g, '.')
      var maximumFractionDigits = 0
      var minimumFractionDigits = 0

      if (format.toLowerCase() === 'f2t') {
        maximumFractionDigits = 2
        minimumFractionDigits = 2
      }
      if (format.toLowerCase() === 'f0t') {
        maximumFractionDigits = 0
        minimumFractionDigits = 0
      }
      if (format.toLowerCase() === 'f1t') {
        maximumFractionDigits = 1
        minimumFractionDigits = 1
      }
      var vvv = (1 * value).toLocaleString('de-DE', {
        style: 'decimal',
        useGrouping: true,
        maximumFractionDigits: maximumFractionDigits,
        minimumFractionDigits: minimumFractionDigits
      })
      if (vvv === 'NaN') {
        return oldValue
      }
      return vvv
    } catch (ee) {
      console.log('x')
    }
    return oldValue
  },
  getSubscription (fnSuccess, fnError) {
    let url = 'https://portal.einfachwoohoo.de/subscriptions.json'
    let params = {
      time: new Date().getTime()
    }
    let cmp = this
    axios({
      method: 'get',
      params: params,
      url: url,
      responseType: 'json'
    })
    .then(function (response) {
      Vue.prototype.$api.hideIdle()
      if (fnSuccess) {
        cmp.subscriptionList = response.data
        fnSuccess(response.data)
      }
    }).catch(error => {
        console.log(error.response)
        Vue.prototype.$api.hideIdle()
        if (fnError) {
          fnError(error.response)
        } else {
          Vue.prototype.$eventHub.$emit('errorDlg', error.response)
        }
      })
  },
  getHost: function () {
    return Vue.config.host
  },
  hideIdle: function () {
    Vue.prototype.$eventHub.$emit('OnIdle', false)
  }
}
export default BaseApi
