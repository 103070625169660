<template>
    <div id="app">
        <v-app>
            <v-container class="mb-16">
                <v-layout row wrap justify-center class="mb-16">

                    <v-flex xs11 sm10 md4 lg4 class="custom-box" v-for="(data, index) in datas" :key="index"  v-bind:class="(index==2)?'third-box':''">
                        <v-card v-bind:class="[checkIfCenter(index)?'center-box':'']" :dark="checkIfCenter(index)" tile>
                            <v-card-title primary-title class="justify-center">
                                <h2 class="text-xs-center font-weight-light greytext">{{data.title}}</h2>
                            </v-card-title>
                            <v-card-text class="pa-10">
                                <v-flex xs12>
                                    <h2 class="price text-center" v-if="data.price">{{data.price}} €</h2>
                                    <h2 class="price text-center" v-if="!data.price">&nbsp;</h2>
                                    <h2 class="price text-center" v-if="data.priceText">{{data.priceText}}</h2>
                                    <div class="small-title text-center black--text" v-if="data.price">jährlich</div>
                                    <div class="small-title text-center black--text" v-if="!data.price">&nbsp;</div>
                                </v-flex>
                                <v-layout row wrap v-for="(item, ind) in data.items" :key="ind" class="border text-center black--text">
                                    <v-flex xs12>
                                         <div class="feature-label">
                                            {{item.label}}
                                         </div>
                                    </v-flex>
                                </v-layout>
                            </v-card-text>
                            <!--
                            <div style="text-align:center">
                                <v-btn rounded v-bind:class="[checkIfCenter(index)?'custom-btn':'']" class="text-none bluetext" color="#c0ddff" tile depressed>Buy Now</v-btn>
                            </div>
                            -->
                        </v-card>
                    </v-flex>


                </v-layout>
            </v-container>
        </v-app>
    </div>


</template>

<script>

export default {
  name: 'price',
  components: {},
    data() {
        return {
            datas: [
                { title: 'Free', price: '',
                    priceText: 'Kostenlos',
                    items: [
                        {label: 'Forum Support'},
                        {label: '100 Dokumente'},
                        {label: 'Export PDF'},
                        {label: 'Volltextindizierung'},
                        {label: 'Import von PDF Dokumenten'},
                        {label: 'Finden Feature'},
                        {label: 'Scanner Feature'},
                        {label: 'Scandateien zusammenfügen'},
                        {label: 'Scandateien Reihenfolge'},
                        {label: 'Bis zu 4 Dokument Kategorieren'},
                        {label: 'Bis zu 4 Dokument Stati'},
                        {label: 'Feste Kategorie Felder'}
                    ]
                },
                { title: 'Premium', price: 219,
                    priceText: 'Private Nutzung',
                    items: [
                        {label: 'E-Mail Support'},
                        {label: '10.000 Dokumente'},
                        {label: 'Export PDF'},
                        {label: 'Volltextindizierung'},
                        {label: 'Import von PDF/Word/Excel u.v.w'},
                        {label: 'E-Mail versand'},
                        {label: 'Finden Feature'},
                        {label: 'Scanner Feature'},
                        {label: 'Scandateien zusammenfügen'},
                        {label: 'Scandateien Reihenfolge'},
                        {label: 'Dokument Status (zu zahlen, bezahlt, usw.)'},
                        {label: 'Frei definierbare Kategorie Felder (netto, brutto, usw.)'},
                        {label: 'grenzenlos Dokument Kategorieren'},
                        {label: 'grenzenlos Dokument Stati'},
                        {label: 'Statistische Auswertung / Summierung von Kategorie Feldern'}
                    ]
                },
                { title: 'Business',
                    price: 2400,
                    priceText: 'Geschäftliche Nutzung',
                    items: [
                        {label: 'E-Mail / Telefon - Support'},
                        {label: 'grenzenlos viele Dokumente'},
                        {label: 'Export PDF'},
                        {label: 'Volltextindizierung'},
                        {label: 'Import von PDF/Word/Excel u.v.w'},
                        {label: 'E-Mail versand'},
                        {label: 'Auswertung'},
                        {label: 'Finden Feature'},
                        {label: 'Scanner Feature'},
                        {label: 'Scandateien zusammenfügen'},
                        {label: 'Scandateien Reihenfolge'},
                        {label: 'Dokument Status (zu zahlen, bezahlt, usw.)'},
                        {label: 'Frei definierbare Kategorie Felder (netto, brutto, usw.)'},
                        {label: 'grenzenlos Dokument Kategorieren'},
                        {label: 'grenzenlos Dokument Stati'},
                        {label: 'Statistische Auswertung / Summierung von Kategorie Feldern'}
                    ]
                },
            ],
        }
    },
    methods: {
        checkIfCenter(index){
            return index==1;
        }
    }
}
</script>

<style scoped>
    .price{
        color: black;
    }
    .yes{
        color: #63a378;
        font-size: 30px;
    }
    .no{
        color: #c23346;
    }
    .small-title{
        font-size: 10px;
    }
    .v-card.theme--dark{
        background-color: #8AB3BA !important;
    }
    .v-card{
        padding-bottom: 20px;
    }
    .custom-btn{
        background-color: #3a4780 !important;
        color: #c0ddff;
    }
    .v-card__title{
        padding-bottom:0px;
    }
    .custom-box{
        padding-top:30px !important;
    }
    .center-box{
        outline: #8AB3BA solid 10px;
    }
    .third-box{
        padding-left:10px !important;

    }
    .border{
        border-bottom: #6f6f6e 1px solid;
        margin-top: 10px;
        margin-bottom: 15px;
        padding-bottom: 20px;
    }
</style>
