<template>
  <v-dialog v-model="dialog" max-width="640px">
    <v-card>
      <v-card-text class="font-weight-light text-xs-left title underlineheadline bluetext">
        {{config.title}}
      </v-card-text>
      <v-card-text>

        <v-layout class="pa-1" row wrap>
          <v-flex xs6 class="text-xs-center pa-4" style="vertical-align: middle">
            <span>{{config.message}}</span>
          </v-flex>
          <v-flex v-if="config.isWarning">
            <div class="f-modal-alert">
              <div class="f-modal-icon f-modal-warning scaleWarning">
                <span class="f-modal-body pulseWarningIns"></span>
                <span class="f-modal-dot pulseWarningIns"></span>
              </div>
            </div>
          </v-flex>
          <v-flex xs6 class="text-xs-center" v-if="config.success">
            <div class="f-modal-alert">
              <div class="f-modal-icon f-modal-success animate">
                <span class="f-modal-line f-modal-tip animateSuccessTip"></span>
                <span class="f-modal-line f-modal-long animateSuccessLong"></span>
                <div class="f-modal-placeholder"></div>
                <div class="f-modal-fix"></div>
              </div>
            </div>
          </v-flex>
          <v-flex xs6 class="text-xs-center" v-if="config.failed" style="min-height: 200px;">
            <div class="f-modal-alert">
              <div class="f-modal-icon f-modal-error animate">
                  <span class="f-modal-x-mark">
                    <span class="f-modal-line f-modal-left animateXLeft"></span>
                    <span class="f-modal-line f-modal-right animateXRight"></span>
                  </span>
                <div class="f-modal-placeholder"></div>
                <div class="f-modal-fix"></div>
              </div>
            </div>

          </v-flex>
        </v-layout>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="text-none" color="examenis" outline flat @click="closeDialog()">ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogRegisterDone',
  data () {
    return {
      dialog: false,
      config: {
        success: true
      }
    }
  },
  methods: {
    closeDialog: function () {
      this.dialog = false
      this.$emit('onClose', this.config)
    },
    open: function (config) {
      this.dialog = true
      this.config = config
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
