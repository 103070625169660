<template>
  <v-main>
    <Impressum ref="dlgImpressum"></Impressum>
    <Datenschutz ref="dlgDatenschutz"></Datenschutz>
    <Nutzung ref="dlgNutzung"></Nutzung>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card class="d-flex align-center justify-center text-center" color="transparent" max-height="500px" max-width="100%" flat>
            <kinesis-container style="overflow: hidden; max-width: 100%; margin-top: -800px">
              <kinesis-element
                  tag="img"
                  src="static/woohoo_Background_kreis1.png"
                  :strength="-10"
                  type="depth"
                  transformOrigin="50% 300%"
                  axis="x"/>
            </kinesis-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row style="margin-top: -550px">
        <v-col cols="12">
          <v-card class="d-flex align-center justify-center text-center" color="transparent" height="800px" flat>
            <kinesis-container>
              <kinesis-element :strength="10" type="depth"
                               tag="img"
                               src="static/logo_woohoo_schrift.png"
              >
              </kinesis-element>
              <kinesis-element :strength="10" class="logowoohoo">
                einfach finden
              </kinesis-element>
              <kinesis-element :strength="30" class="textwoohoo">
                Dein Weg aus dem Papierchaos.
              </kinesis-element>
              <kinesis-element :strength="10" class="textwoohoo font-weight-bold mt-10" type="depth">
                <v-btn dark depressed color="#8AB3BA" class="text-none" block @click="showWay" large style="font-size: 0.8em !important;">Jetzt registrieren</v-btn>
              </kinesis-element>
            </kinesis-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card color="transparent" flat tile class="text-center">
              <video src="static/Woohoo_Trailer.mp4" ref="player" controls width="500" class="mx-auto" :style="getVideoStyle()"></video>
            <v-card-text class="text-center text-h5 textwoohoo font-weight-bold mb-10 mt-5">
              <v-icon
                  large
                  color="#804F69"
              >
                mdi-motion-play-outline
              </v-icon> woohoo in 1 Minute 30
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div class="diagonal"></div>
    <v-container class="pa-5" style="background-color: #8AB3BA" fluid>
      <v-container>
      <v-row class="mt-5">
        <v-col class="pt-0 pb-0">
          <v-card color="transparent" flat tile>
            <v-card-text class="text-center text-h3 white--text">warum woohoo?</v-card-text>
            <v-row>
              <v-col cols="6" class="hidden-sm-and-down">
                <v-card flat color="transparent">
                  <v-row>
                    <v-col cols="12">
                      <v-row
                          align="center"
                          justify="center"
                      >
                        <v-card
                            class="ma-3 pa-6"
                            outlined
                            tile
                            flat
                            color="transparent"
                        >
                          <div class="text-h5 font-weight-medium textcolor white--text">Papierkram endlich einfach</div>
                          <div class="text-h6 mt-4 textcolor white--text font-weight-medium ">
                            <ul>
                              <li> kein lästiges abheften</li>
                              <li> smartes hinzufügen (drag and drop)</li>
                              <li> Excel Tabellen importieren, finden, downloaden</li>
                              <li> Word Dokumente importieren, finden, downloaden</li>
                              <li>einfaches scannen</li>
                              <li> schnelles finden</li>
                              <li>Statistik <br>(z.b. Summe von Kosten im Monat)</li>
                              <li>Unterscheidung in Kategorien</li>
                              <li>Konvertierung nach PDF</li>
                            </ul>
                          </div>
                        </v-card>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" class="hidden-md-and-up">
                <v-img src="static/slider_Hinzufuegen_small_white.png">
                </v-img>
              </v-col>
              <v-col cols="12" class="hidden-md-and-up">
                <v-card flat color="transparent">
                  <v-row>
                    <v-col cols="12">
                      <v-row
                          align="center"
                          justify="center"
                      >
                        <v-card
                            class="ma-3 pa-6"
                            outlined
                            tile
                            flat
                            color="transparent"
                        >
                          <div class="text-h5 font-weight-medium  textcolor white--text">Papierkram endlich einfach</div>
                          <div class="text-h6 mt-4 textcolor white--text font-weight-medium ">
                            <ul>
                              <li> kein lästiges abheften </li>
                              <li> smartes hinzufügen <br> (drag and drop)</li>
                              <li> Excel Tabellen importieren, finden, downloaden</li>
                              <li> Word Dokumente importieren, finden, downloaden</li>
                              <li>einfaches scannen </li>
                              <li> schnelles finden </li>
                              <li>Statistik <br>(z.b. Summe von Kosten im Monat)</li>
                              <li>Unterscheidung in Kategorien</li>
                              <li>Konvertierung nach PDF</li>
                            </ul>
                          </div>
                        </v-card>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="6" class="hidden-sm-and-down">
                <v-img src="static/slider_Hinzufuegen_small_white.png" class="margintoppic">
                </v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      </v-container>
    </v-container>
    <v-container class="mt-16">
      <v-row>
        <v-col>
          <v-card class="textwoohoo textcolor text-center text-h4" flat color="transparent">
            Dein Weg zum woohoo - Effekt
          </v-card>
        </v-col>
      </v-row>
      <v-container>
        <v-row>
          <v-col cols="12" xs="12" md="6">
            <v-img src="static/woohoo-hinzu.png">
            </v-img>
          </v-col>
          <v-col cols="12" xs="12" md="6">
            <v-card flat color="transparent">
              <v-row>
                <v-col cols="12">
                  <v-row
                      align="center"
                      justify="center"
                  >
                    <v-card
                        class="ma-3 pa-6"
                        outlined
                        tile
                        flat
                        color="transparent"
                    >
                      <div class="text-h5 font-weight-medium textcolor">Datein hinzufügen</div>
                      <div class="text-h6 mt-4 textcolor font-weight-medium ">
                        So praktisch: Dokumente per Drag und Drop aus deinem E-Mail Programm, von deinem Rechner, deinem Scanner oder ein Bild direkt vom Handy importieren. Alle Dateien stehen anschließend als PDF zur Verfügung.
                      </div>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs="12" md="6">
            <v-card flat color="transparent">
              <v-row>
                <v-col cols="12">
                  <v-row
                      align="center"
                      justify="center"
                  >
                    <v-card
                        class="ma-3 pa-6"
                        outlined
                        tile
                        flat
                        color="transparent"
                    >
                      <div class="text-h5 font-weight-medium textcolor">woohoo Effekt</div>
                      <div class="text-h6 mt-4 textcolor font-weight-medium ">
                        woohoo analysiert, erkennt elementare Inhalte und ordnet sie für dich.
                      </div>
                      <div class="text-h5 font-weight-medium textcolor mt-5">Noch mehr woohoo</div>
                      <div class="text-h6 mt-4 textcolor font-weight-medium ">
                        Erstelle deine eigene Kategorie, mit den Informationen die für dich wichtig sind, durch Hinzufügen deiner Kategoriefelder.
                        <br><br>
                        Somit kannst du auf einem Blick sehen, wer der Anbieter oder wie hoch der Gesamtpreis ist.
                        Die woohoo Chronik sagt dir genau, wann du einen Status gesetzt, eine Mail verschickt oder eine Notiz erstellt hast.
                      </div>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" md="6">
            <v-img src="static/woohoo_analysieren.png">
            </v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card flat color="transparent">
              <v-row>
                <v-col cols="12">
                  <v-row
                      align="center"
                      justify="center"
                  >
                    <v-col cols="12">
                    <v-card
                        class="ma-3 pa-6"
                        outlined
                        tile
                        flat
                        color="transparent"
                    >
                      <div class="text-h5 font-weight-medium textcolor text-center">Find auch du mit woohoo </div>
                    </v-card>
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <v-card
                          max-height="350"
                          class="text-center"
                          color="rgba(255,255,255, 0.3)"
                      >
                        <v-img
                            class="white--text align-end mx-auto"
                            src="static/woohoo_einfach.png"
                            height="200" width="200"
                        >
                        </v-img>
                        <v-card color="#804F69" class="pa-0 mb-3" flat tile>
                          <v-card-title class="white--text pa-0">
                            <v-spacer></v-spacer>
                            einfach
                            <v-spacer></v-spacer>
                          </v-card-title>
                        </v-card>
                      </v-card>
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <v-card
                          max-height="350"
                          class="text-center"
                          color="rgba(255,255,255, 0.3)"
                      >
                        <v-img
                            class="white--text align-end mx-auto"
                            src="static/woohoo_info.png"
                            height="200" width="200"
                        >
                        </v-img>
                        <v-card color="#804F69" class="pa-0 mb-3" flat tile>
                          <v-card-title class="white--text pa-0">
                            <v-spacer></v-spacer>
                            informativ
                            <v-spacer></v-spacer>
                          </v-card-title>
                        </v-card>
                      </v-card>
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <v-card
                          max-height="350"
                          class="text-center"
                          color="rgba(255,255,255, 0.3)"
                      >
                        <v-img
                            class="white--text align-end mx-auto"
                            src="static/woohoo_du.png"
                            height="200" width="220"
                        >
                        </v-img>
                        <v-card color="#804F69" class="pa-0 mb-3" flat tile>
                          <v-card-title class="white--text pa-0">
                            <v-spacer></v-spacer>
                            auf dich zugeschnitten
                            <v-spacer></v-spacer>
                          </v-card-title>
                        </v-card>
                      </v-card>
                    </v-col>
                   <!-- <v-col cols="12">
                      <v-card
                          class="ma-3 pa-6"
                          outlined
                          tile
                          flat
                          color="transparent"
                      >
                        <div class="text-h5 font-weight-medium textcolor text-center">einfach, informativ, auf dich zugeschnitten, was du brauchst, wann du es brauchst </div>
                      </v-card>
                    </v-col>-->
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container fluid style="background-color: #1f2530" class="pa-0">
      <div class="diagonal12"></div>
    </v-container>
    <v-container fluid style="background-color: #1f2530">
      <v-container>
      <v-row>
        <v-col>
          <v-card flat class="text-center text-h4 font-weight-light lighttext" color="transparent">
            Mit <span class="font-weight-bold">woohoo</span> hast du deine Kosten im Blick
          </v-card>
          <v-card flat class="text-center text-h5 font-weight-light lighttext mt-10" color="transparent">
            Lege mit <span class="font-weight-bold">woohoo</span> deine persönlichen Kategorien fest <br>
            und analysiere damit deine Ausgaben.
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="space-around" class="mt-6">
        <v-col cols="12" xs="12" md="6" lg="4">
        <PieChart ref="pieChart1" :chartData="pieChartData" :options="pieChartOptions"></PieChart>
        </v-col>
        <v-col cols="12" xs="12" md="6" lg="6">
          <v-card flat class="text-left text-h5 font-weight-light lighttext mt-10" color="transparent">
            So hast du eine weitere Filterfunktion deiner Daten.
            <br><br>
            Du möchtest ein Haus kaufen und brauchst verschiedene Dokumente griffbereit?<br>
            Mit <span class="font-weight-bold">woohoo</span> kein Problem.<br> <br>
            Lege dazu eine Kategorie <span class="font-italic">&bdquo;Mein neues Heim&ldquo;</span> an und mit einem Klick hast du die Dokumente die du brauchst.
            <br>
            <span class="font-weight-bold">woohoo</span>
          </v-card>
        </v-col>
      </v-row>
      </v-container>
    </v-container>
    <v-container fluid style="background-color: #1f2530" class="pa-0">
    <div class="diagonal"></div>
    </v-container>
    <v-container fluid style="background-color: #8AB3BA">
      <v-container class="mt-5 mb-16">
        <v-row class="">
          <v-col>
            <v-card flat class="text-center text-h4 font-weight-light white--text" color="transparent">
              Keine Lust mehr im  <br>Dokumenten Sumpf zu verharren?
            </v-card>
            <v-card class="text-center mt-10" flat color="transparent">
                    <span
                        class="txt-rotate text-h5 font-weight-light white--text"
                        data-period="2000"
                        data-rotate='[ "Rechnung?", "Handyrechnung?", "ähm?", "ahhhh" ]'></span>
            </v-card>

            <v-card flat class="text-center text-h4 font-weight-light mt-10 white--text" color="transparent">
              Deine Zeit ist zu kostbar, um Dateien zu suchen
            </v-card>
            <v-card flat class="text-center text-h4 font-weight-light mt-16 textcolor" color="transparent">
              <v-btn depressed dark color="#804F69" class="text-none" large style="font-size: 0.8em !important;" to="registrieren">Finde sie jetzt!</v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container class="mt-16 mb-16">
      <v-row id="pricesRef" class="mb-16" justify="space-around">
        <v-col cols="12">
          <v-card-text class="text-center text-h4 textcolor">woohoo Preise</v-card-text>
        </v-col>
        <v-col cols="12" xs="12" md="3" v-if="free">
          <v-card
              class="text-center pa-2"
              color="#8AB3BA"
          >
            <v-img
                class="white--text align-end mx-auto"
                src="static/woohoo_free.png"
                width="180"

            ></v-img>
              <v-card class="pa-0 mb-3 mt-6" flat tile>
                <v-card-title class="textwoohoo pa-0 text-h5">
                  <v-spacer></v-spacer>
                  woohoo free
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-title class="textwoohoo text-left font-weight-light subtitle-1">

                  <ul>
                    <li>bis zu {{free.documentsAll}} <br> Dokumente hochladen</li>
                    <li>bis zu {{free.categoriesAll}} Kategorien</li>
                    <li>bis zu {{free.statusAll}} Stati</li>
                  </ul>
                </v-card-title>
                <v-card-text class="text-h6 text-right">
                  kostenlos
                </v-card-text>
              </v-card>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" md="3" v-if="prem">
          <v-card
              class="text-center pa-2"
              color="#804F69"
          >
            <v-img
                class="white--text align-end mx-auto"
                src="static/woohoo_premium.png"
                width="200"
            ></v-img>
            <v-card class="pa-0 mb-3 mt-5" flat tile>
              <v-card-title class="textwoohoo pa-0 text-h5">
                <v-spacer></v-spacer>
                woohoo premium
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-title class="textwoohoo text-left font-weight-light subtitle-1">
                <ul>
                  <li>bis zu {{prem.documentsAll}} <br> Dokumente hochladen</li>
                  <li>bis zu {{prem.shareAll}} Freigaben</li>
                  <li v-if="prem.categoriesAll !== -1">bis zu {{prem.categoriesAll}} Kategorien</li>
                  <li v-if="prem.categoriesAll !== -1">bis zu {{prem.statusAll}} Stati</li>
                  <li>unbegrenzte Kategorien</li>
                  <li>unbegrenzte Stati</li>
                </ul>
              </v-card-title>
              <v-card-text class="text-h6 text-right">
                {{ prem.priceMonthly }} €/ Monat<br>
                <span class="caption"> zahlbar jährlich {{ prem.priceYearly }} €</span>
              </v-card-text>
            </v-card>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-card
              class="text-center pa-2"
              color="#4A4C6D"
          >
            <v-img
                class="white--text align-end mx-auto"
                src="static/woohoo_business.png"
                width="200"
            ></v-img>
            <v-card class="pa-0 mb-3 mt-5 woohoolink" flat tile>
              <v-card-title class="textwoohoo pa-0 text-h5">
                <v-spacer></v-spacer>
                woohoo business
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text class="textwoohoo text-left font-weight-light subtitle-1 mt-3">
                Du möchtest eine Business Lösung zugeschnitten auf dein Unternehmen? woohoo macht es möglich.
                Melde dich einfach unter <a href="mailto:woohoo@edologic.de">woohoo@edologic.de</a>
              </v-card-text>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mb-16" style="background-color: white" fluid>
      <v-row>
        <v-col cols="12">

          <v-card
              class="d-flex align-center mb-6 text-center"
              color="transparent"
              flat
              tile
          >
            <div id=containerfont>
              einfach
              <div id=flip>
                <div>
                  <div>auswerten</div>
                </div>
                <div>
                  <div>finden</div>
                </div>
                <div>
                  <div>verschicken</div>
                </div>
              </div>
              woohoo!
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-5 mb-16">
        <v-col cols="6">
          <v-btn depressed dark color="#8AB3BA" class="text-none font-weight-bold" block to="registrieren" large style="font-size: 1.2em !important;">Jetzt finden</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card height="50" flat color="transparent"></v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="mt-n16 pa-0" style="background-color: white">
      <v-row>
        <v-col class="pb-0">
          <v-card  flat class="diagonaldarkfooter" tile>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-card color="#1f2530" flat tile>
            <v-container fluid>
              <v-row
                  justify="center"
                  wrap
              >
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-card color="transparent" flat>
                    <v-row justify="center">
                      <v-img src="static/logo_woohoo_schrift_neg.png" max-height="79px" max-width="155px" class="mt-2 hidden-md-and-up ml-n6"/>
                    </v-row>
                    <v-row justify="center">
                      <v-img src="static/logo_woohoo_schrift_neg.png" max-height="79px" max-width="155px" class="mt-2 hidden-sm-and-down"/>
                      <span class="white--text ml-5 mt-5 text-body-2">Ein Produkt der edologic GmbH
                                        <br> <br> <br>
                                         <span class="font-weight-bold">edologic GmbH</span>
                                            <br>
                                            In der Hardt 3<br>
                                            42399 Wuppertal<br><br>
                                            <a href="https://www.edologic.de" style="text-decoration: none; color: white; font-size: 16px">www.edologic.de</a><br>
                                            <a href="mailto:info@edologic.de" style="text-decoration: none; color: white; font-size: 16px">info@edologic.de</a>
                                        </span>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <v-card color="transparent" flat>
                    <v-row justify="center">
                      <v-card color="transparent" flat width="250" class="mt-6  ml-9">
                        <v-row justify="start" class="ml-5">
                           <span class="white--text text-body-2">
                            <span class="font-weight-bold">Informationen</span>
                              <br><br>
                                <v-btn text small dark class="text-none ml-n3" @click="openImpressum"> Impressum</v-btn><br>
                                <v-btn text small dark class="text-none ml-n3" @click="openNutzung"> Nutzungsbedingungen</v-btn><br>
                                <v-btn text small dark class="text-none ml-n3" @click="openDatenschutz"> Datenschutz</v-btn>
                           </span>
                        </v-row>
                      </v-card>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-footer color="#8AB3BA" class="pa-3 text-caption"   id="contactRef" ref='contactRef'>
        <v-spacer></v-spacer>
        <div>&copy; {{ new Date().getFullYear() }} edologic. All Rights Reserved.</div>
      </v-footer>
    </v-container>
  </v-main>
</template>

<script>
import Vue from 'vue'
import {KinesisContainer, KinesisElement} from 'vue-kinesis'
import Impressum from "./Impressum";
import Datenschutz from "./Datenschutz";
import Nutzung from "./Nutzung";
import PieChart from './charts/PieChart'
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'
// import card from './Card'
import $ from 'jquery'

export default {
  name: 'Home',
  // eslint-disable-next-line vue/no-unused-components
  components: {ChartJSPluginDatalabels, KinesisContainer, KinesisElement,Impressum,Datenschutz,Nutzung,PieChart},
  data() {
    return {
      fab: false,
      isMobile: false,
      free: null,
      prem: null,
      valueDeterminate: 80,
      valueDeterminate1: 56,
      scrollOptions: {
        duration: 2100,
        offset: 0,
        easing: 'easeInOutCubic'
      },
      pieChartData: {
        hoverBackgroundColor: 'red',
        hoverBorderWidth: 10,
        labels: ['Rechnungen', 'Ausgangsrechnungen', 'Mein neues Heim'],
        datasets: [{
          label: 'Gesamt',
          datalabels: {
            formatter: function(value, context) {
              return context.chart.data.labels[context.dataIndex];
            }
          },
          data: [19, 24, 10,],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(139, 195, 74, 0.2)',
            'rgba(54, 162, 235, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(139, 195, 74, 1)',
            'rgba(54, 162, 235, 1)',
          ],
          borderWidth: 1
        }]
      },
      pieChartOptions: {
        plugins: {
          datalabels: {
            color: 'white',
            textAlign: 'center',
            font: {
              weight: 'normal',
              size: 16
            }
          }
        },
        legend: {
          display: false
        },
        hoverBorderWidth: 20,
      },
    }
  },
  methods: {
    openImpressum: function () {
      this.$refs.dlgImpressum.opendialog()
    },
    openDatenschutz: function () {
      this.$refs.dlgDatenschutz.opendialog()
    },
    openNutzung: function () {
      this.$refs.dlgNutzung.opendialog()
    },
    showWay () {
      // this.$router.push('/registrieren')
      window.open('https://einfachwoohoo.de/registrieren', self)
    },
    getVideoStyle () {
      let s = 'border: 3px solid #804F69;'
      if (this.isMobile) {
        s = s + 'max-width: 320px;'
      }
      return s
    },
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 5
      this.fab = top > 0
    },
    toTop() {
      //this.$vuetify.goTo(0)
      window.location = '#top'
    },
    scrollTo(name) {
      // this.$vuetify.goTo(this.$refs[name], this.scrollOptions)
      window.location = '#' + name
    },
    downloadMac: function () {
      window.open('https://surfandscan.de/download/SurfAndScanInstaller.zip', '_new')
    },
    downloadWindows: function () {
      window.open('https://surfandscan.de/download/SurfAndScanInstaller.zip', '_new')
    },
    TxtRotate: function (el, toRotate, period) {
      this.toRotate = toRotate;
      this.el = el;
      this.loopNum = 0;
      this.period = parseInt(period, 10) || 2000;
      this.txt = '';
      this.tick();
      this.isDeleting = false;
    },

    OnDialogShow: function (dialog) {
      console.log('OnDialogShow', dialog)
      if (dialog === 'datenschutz') {
        this.$refs.dlgDatenschutz.opendialog()
      } else if (dialog === 'impressum') {
        this.$refs.dlgImpressum.opendialog()
      } else if (dialog === 'nutzungsbedingungen') {
        this.$refs.dlgNutzung.opendialog()
      }
    },
    onGetList (list) {
      Vue.prototype.$api.subscriptionList = list
      this.free = Vue.prototype.$api.getSubscriptionWithName('Free')
      this.prem  = Vue.prototype.$api.getSubscriptionWithName('Premium')
      try {
        this.prem.documentsAll = Vue.prototype.$api.convertToDecimal('f0t', this.prem.documentsAll)
        this.prem.priceMonthly = Vue.prototype.$api.convertToDecimal('f2t', this.prem.priceYearly * 1 / 12.0)
        this.prem.priceYearly = Vue.prototype.$api.convertToDecimal('f2t', this.prem.priceYearly)
      } catch (ee) {
        console.log('')
      }
    },
    tick: function () {
      var i = this.loopNum % this.toRotate.length;
      var fullTxt = this.toRotate[i];

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
      }

      this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

      var that = this;
      var delta = 300 - Math.random() * 100;

      if (this.isDeleting) {
        delta /= 2;
      }

      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
      } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
      }

      setTimeout(function () {
        that.tick();
      }, delta);
    }
  },
  mounted() {
    let cmp = this

    Vue.prototype.$api.getSubscription(this.onGetList)


    this.isMobile = this.$vuetify.breakpoint.name === 'xs'
    window.setTimeout(function () {
      cmp.$refs.pieChart1.updateRendering()
    }, 1000)
    var $emitter = $('#emitter'),
        emitEvery = 50, //emit new particle every X ms
        removeAfter = 1000; //remove particles after X ms

    function create() {

      //create particle and random values
      var $particle = $('<div class="particle" />'),
          x = Math.randMinMax(-200, 200),
          y = Math.randMinMax(-200, 50),
          z = Math.randMinMax(-200, 200),
          degree = Math.randMinMax(0, 360),
          color = 'hsla(' + Math.randMinMax(200, 320) + ', 80%, 60%, 1)';

      //append particle to dom
      $particle.css('background', color);
      $emitter.append( $particle );

      //after a short timeout, set css to be transitioned to. Without the timeout, we would not see the transition
      window.setTimeout(function() {
        $particle.css({
          webkitTransform: 'translateX(' + x + 'px) translateY(' + y + 'px) translateZ(' + z + 'px) rotateX(' + degree + 'deg)',
          opacity: 0
        });
      }, 50);

      //remove current particle after time
      window.setTimeout(function() {
        $particle.remove();
      }, removeAfter);

      //create next particle
      window.setTimeout(create, emitEvery);

    }

//https://gist.github.com/timohausmann/4997906
    Math.randMinMax=function(t,n,a){var r=t+Math.random()*(n-t)
      return a&&(r=Math.round(r)),r}

//execute first particle creation
    create();
    var ml4 = {};
    ml4.opacityIn = [0, 1];
    ml4.scaleIn = [0.2, 1];
    ml4.scaleOut = 3;
    ml4.durationIn = 800;
    ml4.durationOut = 600;
    ml4.delay = 500;

    window.anime.timeline({loop: true})
        .add({
          targets: '.ml4 .letters-1',
          opacity: ml4.opacityIn,
          scale: ml4.scaleIn,
          duration: ml4.durationIn
        }).add({
      targets: '.ml4 .letters-1',
      opacity: 0,
      scale: ml4.scaleOut,
      duration: ml4.durationOut,
      easing: "easeInExpo",
      delay: ml4.delay
    }).add({
      targets: '.ml4 .letters-2',
      opacity: ml4.opacityIn,
      scale: ml4.scaleIn,
      duration: ml4.durationIn
    }).add({
      targets: '.ml4 .letters-2',
      opacity: 0,
      scale: ml4.scaleOut,
      duration: ml4.durationOut,
      easing: "easeInExpo",
      delay: ml4.delay
    }).add({
      targets: '.ml4 .letters-3',
      opacity: ml4.opacityIn,
      scale: ml4.scaleIn,
      duration: ml4.durationIn
    }).add({
      targets: '.ml4 .letters-3',
      opacity: 0,
      scale: ml4.scaleOut,
      duration: ml4.durationOut,
      easing: "easeInExpo",
      delay: ml4.delay
    }).add({
      targets: '.ml4',
      opacity: 0,
      duration: 500,
      delay: 500
    });
    var elements = document.getElementsByClassName('txt-rotate');
    for (var i = 0; i < elements.length; i++) {
      var toRotate = elements[i].getAttribute('data-rotate');
      var period = elements[i].getAttribute('data-period');
      if (toRotate) {
        this.TxtRotate(elements[i], JSON.parse(toRotate), period);
      }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
    document.body.appendChild(css);
  },
  created() {
    Vue.prototype.$eventHub.$on('dialog', this.OnDialogShow)
  },
  destroyed() {
    Vue.prototype.$eventHub.$off('dialog', this.OnDialogShow)
  }
}
</script>

<style scoped>
.containerwohoo{
  display: flex;
  justify-content: center;
  flex-wrap:wrap;

}

.containerwohoo .cardwohoo{
  position: relative;
}

.containerwohoo .cardwohoo .face{
  width:300px;
  height: 200px;
  transition:.4s;

}

.containerwohoo .cardwohoo .face.face1{
  position: relative;
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-content:center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
}

.containerwohoo .cardwohoo:hover .face.face1{
  transform: translateY(0);
  box-shadow:
      inset 0 0 60px white,
      inset 20px 0 80px #E2B9C6,
      inset -20px 0 80px #8AB3BA,
      inset 20px 0 300px #E2B9C6,
      inset -20px 0 300px #8AB3BA,
      0 0 50px #fff,
      -10px 0 80px #E2B9C6,
      10px 0 80px #8AB3BA;

}


.containerwohoo .cardwohoo .face.face1 .content{
  opacity: .8;
  transition:  0.5s;
  text-align: center;
}

.containerwohoo .cardwohoo:hover .face.face1 .content{
  opacity: 1;

}

.containerwohoo .cardwohoo .face.face1 .content i{
  font-size: 3em;
  color: white;
  display: inline-block;

}

.containerwohoo .cardwohoo .face.face1 .content h3{
  font-size: 1em;
  color: white;
  text-align: center;


}

.containerwohoo .cardwohoo .face.face1 .content a{
  transition: .5s;
}

.containerwohoo .cardwohoo .face.face2{
  position: relative;
  background: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgba(0,0,0,.8);
  transform: translateY(-100px);
}

.containerwohoo .cardwohoo:hover .face.face2{
  transform: translateY(0);


}

.containerwohoo .cardwohoo .face.face2 .content p, a{
  font-size: 14pt;
  margin: 0 ;
  padding: 0;
  color:#6f6f6f;
}

.containerwohoo .cardwohoo .face.face2 .content a{
  text-decoration:none;
  color: black;
  box-sizing: border-box;
  outline : 1px dashed #333;
  padding: 10px;
  margin: 15px 0 0;
  display: inline-block;
}

.containerwohoo .cardwohoo .face.face2 .content a:hover{
  background: #333 ;
  color: whitesmoke;
  box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);
}

#containerfont {
  color: #6f6f6f;
  text-transform: uppercase;
  font-family: 'Raleway', sans-serif;
  font-size:36px;
  font-weight:bold;
  padding-top:200px;
  width:100%;
  display:block;
}

#flip {
  height:50px;
  overflow:hidden;
}

#flip > div > div {
  color:#fff;
  padding:4px 12px;
  height:45px;
  margin-bottom:45px;
  display:inline-block;
}

#flip div:first-child {
  animation: show 5s linear infinite;
}

#flip div div {
  background:#8AB3BA;
}
#flip div:first-child div {
  background:#4A4C6D;
}
#flip div:last-child div {
  background:#804F69;
}

@keyframes show {
  0% {margin-top:-270px;}
  5% {margin-top:-180px;}
  33% {margin-top:-180px;}
  38% {margin-top:-90px;}
  66% {margin-top:-90px;}
  71% {margin-top:0px;}
  99.99% {margin-top:0px;}
  100% {margin-top:-270px;}
}

</style>
