<template>
    <v-main>
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-app-bar flat app color="white">
                  <v-img src="static/logo_woohoo_schrift.png" max-width="100px" @click="dialog = false"/>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                  </v-toolbar-items>
                </v-app-bar>
                <v-main>
                <v-col cols="12">
                    <v-card-text class="display-3 font-weight-bold mt-5 hidden-xs-only">
                        Nutzungsbedingungen
                        <span itemprop="name">woohoo.</span>
                    </v-card-text>
                    <v-card-text class="text-h6 font-weight-bold mt-5 hidden-sm-and-up">
                        Nutzungsbedingungen
                        <span itemprop="name">edologic.</span>
                    </v-card-text>
                    <v-card-text class="headline font-weight-light">
                        Nutzungsbedingungen für die edologic Anwendung „woohoo“<br>
                        Die nachfolgenden Nutzungsbedingungen regeln die Nutzung der von der edologic GmbH (nachfolgend „edologic“) unter der Bezeichnung „sas“ angebotenen Anwendung (nachfolgend „Anwendung“).
                        <br><br>
                        Diese Nutzungsbedingungen gelten als Endnutzer-Lizenzvereinbarung ausschließlich für das Vertragsverhältnis zwischen der edologic und den Nutzern in Bezug auf die Anwendung und deren jeweiligen Updates.
                        <br><br>
                        Anbieter<br>
                        edologic GmbH<br>
                        Amtsgericht Wuppertal<br>
                        Handelsregisternummer: HRB 21305<br>
                        Umsatzsteueridentifikationsnummer: DE261151249<br>
                        <br>
                        Hausanschrift:<br>
                        In der Hardt 3<br>
                        42399 Wuppertal<br>
                        <br>
                        Telefon: +49 (0) 202 / 947 996 20<br>
                        E-Mail: info@edologic.de<br>
                        <br>
                        Geschäftsführer:<br>
                        Matthias Ebel<br>
                        <br>
                        Beschreibung der Funktionen:<br>
                        <span style="color: #c23346; font-weight: bold">PDF Dokumente Zentral archiviert</span><br>
                        <span>Mit SurfAndScan können PDF Dokumente an einem zentralen Ort archiviert werden. Dieser Ort kann frei vom Benutzer gewählt werden.</span>
                        <br><br>
                        <span style="color: #c23346; font-weight: bold">PDF Fulltext Indizierung</span><br>
                        <span>SurfAndScan extrahiert Text aus den PDF Dokumenten und speichert diese in eine lokale Datenbank</span>
                        <br><br>
                        <span style="color: #c23346; font-weight: bold">PDF Dokumente finden</span><br>
                        <span>Mit SurfAndScan können PDF Dokumente mittels der Browser Oberfläche gefunden werden. Dokumente werden anhand der zusätzlich eingegebenen Attribute gefunden oder mittels dem extrahierten Text aus dem PDF</span>
                        <br><br>
                        <span style="color: #c23346; font-weight: bold">Scanner ansprechen</span><br>
                        <span>Mit SurfAndScan können eine vielzahl von Scanner angesprochen werden. Aus den gescannten Seiten kann ein PDF erstellt werden. Es wird nicht empfohlen die TWAIN Schnittstelle zuverwenden, da diese veraltet ist und Multi Threading deaktiviert.</span>
                        <br><br>
                        <span style="color: #c23346; font-weight: bold">OCR Erkennung</span><br>
                        <span>Mit SurfAndScan können PDF Bilddateien nach Text konvertiert werden. Hierzu wird die erste Seite eines PDF anonym an einem Webservice versendet und dort nach Text gewandelt. Die Bilddatei wird dazu kurzfristig in der Cloud gespeichert und nach Erkennung wieder gelöscht.
                            Ein Rechtsanspruch auf die dauerhafte Verfügbarkeit des OCR Services besteht nicht. Es kann nicht gewährleistet werden, dass bei einem hohen Traffic dieser Service jederzeit erreichbar ist.
                        </span>
                        <br>
                        <br>
                        Weitere Funktionen<br>
                        <span style="color: #c23346; font-weight: bold">
                        <br>
                        ° Statistische Auswertung von Suchergebnissen<br>
                        ° Verwaltung von Dokument Kategorien<br>
                        ° Verwaltung von Zusatzfelder von Kategorien<br>
                        ° Verwaltung von Statusfelder<br>
                        </span>
                        <br>
                        Die Anwendung sowie ihre Updates werden dem Benutzer kostenlos, <span style="color: #c23346; font-weight: bold">in der Version „free“ </span>zur Verfügung gestellt. Ein Rechtsanspruch auf deren Nutzung besteht nicht.
                        <br><br>
                        Updates
                        <br><br>
                        Um die Anwendung zukünftig weiter nutzen zu können, kann es von Zeit zu Zeit erforderlich sein, dass Sie ein Update downloaden müssen. Eine Nutzung der Anwendung in der Vorgängerversion kann in diesem Fall nicht mehr möglich sein.
                        <br><br>
                        Nutzungsrecht
                        <br><br>
                        Mit dem Download der Anwendung erhalten Sie das nicht ausschließliche (einfache) Recht, die Anwendung mit ihren geschützten Inhalten nach Maßgabe dieser Nutzungsbedingungen zu nutzen. Das Nutzungsrecht ist nicht auf Dritte übertragbar, und es ist Ihnen nicht gestattet, Dritten Nutzungsrechte an der App einzuräumen. Ferner ist dem Nutzer nicht das Recht eingeräumt, die Anwendung oder deren Inhalte ganz oder teilweise zu vervielfältigen, zu verbreiten, zu veröffentlichen, insbesondere im Internet öffentlich zugänglich zu machen, zu bearbeiten/verändern, den Quellcode oder die Struktur ihrer Inhalte zu ermitteln, oder unter Verwendung ihrer Inhalte abgeleitete Werke herzustellen. Dem Nutzer ist ebenso wenig ein Recht eingeräumt, die in der Anwendung enthaltenen geschützten Marken der edologic zu nutzen. Bei Einstellung des Betriebs der Anwendung endet das Nutzungsverhältnis automatisch, ohne dass es einer Kündigung bedarf.
                        <br><br>
                        Privatgebrauch, Berechtigung am Endgerät
                        <br><br>
                        Die Nutzung der Anwendung in <span style="color: #c23346; font-weight: bold"> der Version „free“ </span>ist nur zu privaten, nichtgewerblichen Zwecken und nur auf einem Endgerät zulässig, das in Ihrem Eigentum steht bzw. zu dessen Nutzung Sie befugt sind.
                        <br><br>
                        Ausschluss von der Nutzung
                        <br><br>
                        Eine über die Regelungen dieser Nutzungsbedingungen hinausgehende Nutzung der von der edologic zur Verfügung gestellten Anwendung ist nicht gestattet. Die edologic behält sich vor, Nutzer bei Verstoß gegen diese Nutzungsbedingungen von der Nutzung der Anwendung auszuschließen bzw. ausschließen zu lassen.
                        <br><br>
                        Keine Garantien
                        <br><br>
                        Die edologic übernimmt mit Bereitstellung der Anwendung keine Garantien irgendwelcher Art, insbesondere nicht hinsichtlich der Richtigkeit oder Zuverlässigkeit der bereitgestellten Informationen und Services, ihrer Sicherheit oder ihrer ununterbrochenen Verfügbarkeit.
                        <br><br>
                        Änderungen der Anwendung
                        <br><br>
                        Die edologic behält sich das Recht vor, die Inhalte und Funktionen der Anwendung ohne vorherige Ankündigung zu ändern, zu entfernen und/oder den Zugang hierzu ganz oder teilweise vorübergehend oder dauerhaft zu deaktivieren.
                        <br><br>
                        Haftungsbeschränkung
                        <br><br>
                        Die edologic haftet dem Nutzer gegenüber nur für vorsätzlich oder grob fahrlässig begangene Handlungen. Davon ausgenommen sind die Haftung für Verletzungen von Leib, Leben und Gesundheit sowie für Verletzungen von Pflichten, die wesentlich für die Erreichung des Vertragszwecks sind (Kardinalpflichten). Bei leicht fahrlässiger Verletzung von Kardinalpflichten ist die Haftung der edologic der Höhe nach begrenzt auf den typischen und bei Vertragsschluss vorhersehbaren Schaden.
                        <br><br>
                        Anwendbares Recht
                        <br><br>
                        Für diesen Vertrag gilt ausschließlich deutsches Recht.
                        <br><br>
                        Änderung der Nutzungsbedingungen
                        <br><br>
                        Die edologic behält sich das Recht vor, die Nutzungsbedingungen jederzeit ohne Angabe von Gründen abzuändern.
                        <br><br>
                        Salvatorische Klausel
                        <br><br>
                        Sollten eine oder mehrere Klauseln dieser Nutzungsbedingungen ganz oder teilweise unwirksam sein oder werden, so wird hierdurch die Gültigkeit der übrigen Bestimmungen nicht berührt. Die unwirksame Klausel/n wird/werden durch (eine) solche wirksame/n Klausel/n ersetzt, die dem Sinn und Zweck der unwirksamen Klausel/n am nächsten kommt/en. Entsprechendes gilt für eventuelle Regelungslücken.
                    </v-card-text>
                </v-col>
                </v-main>
            </v-card>
        </v-dialog>
    </v-main>
</template>

<script>
//import Vue from 'vue'

export default {
  name: 'Nutzung',
  components: {},
  data () {
    return {
        fab: false,
        dialog: false,
        scrollOptions: {
            duration: 2100,
            offset: 0,
            easing: 'easeInOutCubic'
        },
        window: {
            width: 0,
            height: 0
        }
    }
  },
  methods: {
      opendialog () {
          this.dialog = true
      },
      onScroll (e) {
          if (typeof window === 'undefined') return
          const top = window.pageYOffset ||   e.target.scrollTop || 5
          this.fab = top > 0
      },
      toTop () {
          //this.$vuetify.goTo(0)
          window.location = '#top'
      },
      scrollTo (name) {
          // this.$vuetify.goTo(this.$refs[name], this.scrollOptions)
          window.location = '#' + name
      },
  },
  mounted () {
    //Vue.prototype.$api.checkCredentials(this)
  }
}
</script>

<style scoped>

</style>
