<template>
  <v-main>
    <Datenschutz ref="dlgDatenschutz"></Datenschutz>
    <Nutzung ref="dlgNutzung"></Nutzung>
    <dialog-register-done ref="dlgRegister"></dialog-register-done>
    <v-container>
      <v-row align="center" justify="center">
        <v-col
            cols="12"
            md="4"
            class="d-flex justify-center"
        >
          <v-card class="text-center d-flex justify-center" flat color="transparent">
            <v-card-text class="text-h4 textcolor"> Find' auch du mit</v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col
            cols="12"
            md="4"
            class="d-flex justify-center mt-n10"
        >
      <v-img src="static/logo_woohoo_schrift.png" max-width="200"></v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-form v-model="valid" ref="form">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" xs="12" md="8" class="d-flex justify-center flex-wrap">
          <v-col
              cols="12"
              sm="12"
              md="6"
              lg="6"
          >
            <v-text-field
                v-model="userData.email"
                :rules="emailRules"
                label="E-mail"
                solo
                required
                @blur="onBlurEmail"
            ></v-text-field>
          </v-col>
            <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
            >
              <v-text-field
                  v-model="userData.username"
                  :rules="nameRules"
                  label="Benutzername"
                  solo
                  required
              ></v-text-field>
            </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
              lg="6"
          >
            <v-text-field
                v-model="userData.forename"
                solo
                label="Vorname"
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
              lg="6"
          >
            <v-text-field
                v-model="userData.lastname"
                label="Nachname"
                required
                solo
            ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              sm="12"
              md="6"
              lg="6"
          >
          <v-text-field
              v-model="userData.password"
              :rules="passwordRules"
              label="Passwort"
              type="password"
              required
              solo
          ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
              lg="6"
          >
          <v-text-field
              v-model="userData.password1"
              :rules="passwordRules"
              type="password"
              label="Passwort Bestätigung"
              required
              solo
          ></v-text-field>
          </v-col>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" xs="12" md="8" class="d-flex justify-center flex-wrap">
            <v-col cols="12"
                   sm="12"
                   md="6"
                   lg="6" class="mt-n10">
              <v-card-text class="caption textcolor">
                Wenn du auf "Jetzt registrieren" klickst,
                registrierst Du Dich und stimmst der Nutzung Deiner Daten
                (Name, E-Mail-Adresse) sowie den
                 <v-btn @click="openNutzung" x-small text color="#6274af">Nutzungsbedingungen</v-btn>
                und
                <v-btn @click="openDatenschutz" x-small text color="#6274af">Datenschutzbestimmungen</v-btn>
                von woohoo zu.
              </v-card-text>
            </v-col>
          <v-col cols="12"
                 sm="12"
                 md="6"
                 lg="6">
            <v-btn outlined @click="register" class="text-none" color="#6f6f6f" block>Jetzt registrieren</v-btn>
          </v-col>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-main>
</template>

<script>
import DialogRegisterDone from "@/components/dialogs/DialogRegisterDone";
import Datenschutz from "@/components/Datenschutz";
import Nutzung from "@/components/Nutzung";
export default {
  name: 'Register01',
  components: {DialogRegisterDone,Datenschutz,Nutzung},
  data: () => ({
    valid: false,
    api: require('./registerApi').default,
    userData: {
      token: '',
      firstname: '',
      lastname: '',
      email: '',
      username: '',
      password: '',
      password1: ''
    },
    nameRules: [
      v => !!v || 'Name ist erforderlich',
      v => v && v.length <= 32 || 'Name must be less than 32 characters',
    ],
    emailRules: [
      v => !!v || 'E-mail ist erforderlich',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    password: '',
    password1: '',
    passwordRules: [
      v => !!v || 'Passwort ist erforderlich',
      v => v.length >= 8 || 'Passwort mindestens 8 characters',
    ],
  }),
  methods: {
    openDatenschutz: function () {
      this.$refs.dlgDatenschutz.opendialog()
    },
    openNutzung: function () {
      this.$refs.dlgNutzung.opendialog()
    },
    onBlurEmail: function () {
      this.userData.username = this.userData.email
    },
    onGetToken: function (res) {
      console.log('token', res)
      this.userData.token = res.token
    },
    onRegisterFailed: function (res) {
      console.log('onRegisterFailed', res)
      this.$refs.dlgRegister.open({
        title: 'Anmeldung fehlgeschlagen.',
        message: res.message,
        failed: true
      })
    },
    onRegister: function (res) {
      console.log('onRegister', res)
      this.$refs.dlgRegister.open({
        title: 'Anmeldung erfolgreich',
        message: 'Wir haben eine E-Mail an ' + this.userData.email + ' gesendet. Bitte bestätigen Sie diese E-Mail mit einem Klick auf den gelieferten Link.',
        success: true
      })
    },
    register: function () {
      console.log('this.', this.userData)
      if (this.$refs.form.validate()) {
        this.api.register(this.userData, this.onRegister, this.onRegisterFailed)
      }

    }
  },
  mounted () {
    this.api.generateToken(this.onGetToken)
  }
}
</script>

<style scoped>

</style>
